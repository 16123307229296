import React from "react";

import DrvCover from "../Shared/DrvCover/DrvCover";
import DrvContent from "../Shared/DrvContent/DrvContent";
import DrvTabCollapse from "../Shared/DrvTabCollapse/DrvTabCollapse";
import DrvVideoPlayer from "../Shared/DrvVideoPlayer/DrvVideoPlayer";

import {videos, CustomerStoriesData} from "./CustomerStories.data";
import Cover from "../../images/CustomerStories/Cover.png";

const categories = {
    DeliveringGreatDrivers: 'Delivering Great Drivers',
    SomeOfOurFans: 'Some of Our Fans',
    FinancialBenefits: 'Financial Benefits',
    HowDrivosityHelps: 'How Drivosity Helps',
    Productivity: 'Productivity',
    Safety: 'Safety',
    ImpactOnCultureAndOperations: 'Impact On Culture and Operations',
    CustomerServiceAndSatisfication: 'Customer Service and Satisfication'
}
const stories = [];

for (const category in categories) {
    stories.push({
        title: categories[category],
        content: (
            <div className='w-full flex flex-wrap justify-center gap-y-4 gap-x-4'>
                {
                    videos[category].map(item => (
                        <div className='flex justify-center items-center mx-2 xl:mx-0'>
                            <DrvVideoPlayer height='270px' width='270px' image={item.image} url={item.url} />
                        </div>
                    ))
                }
            </div>
        )
    });
}

const CustomerStories = () => {
    return (
        <>
            {/*CONTENT*/}
            <DrvCover
                title={CustomerStoriesData[0].title}
                description={CustomerStoriesData[0].content}
                color='#ffffff'
                image={Cover}
            />

            {/*CONTENT*/}
            <DrvContent
                type={['text']}
                content={[
                    (
                        <>
                            <div className='drv-description'>
                                {CustomerStoriesData[1].content}
                            </div>
                        </>
                    )
                ]}
            />

            {/*CONTENT*/}
            <div className='container mx-auto pb-12'>
                <DrvTabCollapse data={stories} direction='vertical'/>
            </div>
        </>
    )
}

export default CustomerStories;
