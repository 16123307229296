import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import CustomerStories from "../components/CustomerStories/CustomerStories";

import Favicon from "../images/Favicon.png";

const CustomerStoriesPage = () =>{
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Customer Stories - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="See how Drivosity transforms last mile delivery through real customer testimonials. From franchisees, operators, managers, and first-party delivery drivers, learn about the financial benefits, improved productivity, and safety enhancements from those who use our fleet management telematics solution daily. Explore now."/>
            </Helmet>
            <Layout component={<CustomerStories/>}/>
        </>
    )
}

export default CustomerStoriesPage;
